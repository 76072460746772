body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100vh;
}

#root.dragover:after {
  content: 'Přetáhněte soubory sem';
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  border: 10px dashed #fff;
  transition: all 0.5s linear;
}

.grecaptcha-badge {
  display: none;
}

/* Froala WYSIWYG */
.fr-second-toolbar {
  display: none !important;
}

.fr-box.fr-basic .fr-wrapper {
  border-bottom: 1px solid #cccccc !important;
  border-radius: 0 0 10px 10px !important;
}
